import React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from '../components/Layout';
import AddressSearch from '../components/AddressSearch/AddressSearch';
import { slashPrefix, sliceIntoNArrays } from '../utils/utils';
import ZoneMap from '../components/Map/ZoneMap';
import { navigate } from '@reach/router';

export default ({ data, pageContext }) => {
  const zoneData = pageContext.zone;

  const zoneStreets = pageContext.zoneStreets;
  const street = pageContext.street;

  const productCategoryData = pageContext.productCategory;
  const servicesCategories = pageContext.servicesCategories;
  const itemsCategories = pageContext.itemsCategories;

  const title =
    productCategoryData.seoTitle +
    ' - ' +
    street.streetName +
    ' dans le quartier ' +
    zoneData.name +
    ' à ' +
    street.city;
  const description =
    'Vous recherchez ' +
    productCategoryData.seoTitle +
    ' ' +
    street.streetName +
    ' dans le quartier ' +
    zoneData.name +
    ' à ' +
    street.city;

  return (
    <MainLayout siteTitle={title} siteDescription={description}>
      <section className="container section-zone">
        <div className="row">
          <div className="col-12 text-align-center">
            <div className="roboto bold mt-10 mt-5-md pl-2-md pr-2-md font-size-xxl font-size-xl-md">
              Vous recherchez{' '}
              <span className="yellowTag">{productCategoryData.seoTitle}</span>{' '}
              <Link to={`${street.slug}/`} className="text-decoration-none">
                {street.streetName}
              </Link>{' '}
              à {street.city} ?
            </div>

            <div className="roboto mt-10 mt-5-md pl-2-md pr-2-md font-size-xl">
              Alacaza est la première application dédiée à la vie de votre
              résidence et de votre quartier !
            </div>
          </div>
        </div>

        <div className="row mt-10">
          <div className="col-12 font-size-xl text-align-center bold roboto pl-2-md pr-2-md">
            Rejoignez vos voisins du quartier{' '}
            <Link
              to={`/quartiers${slashPrefix(zoneData.slug)}/`}
              className="text-decoration-none"
            >
              <span className="yellowTag">{zoneData.name}</span>
            </Link>{' '}
            !
          </div>
        </div>

        <div className="row">
          <div className="col-4 hidden-sm"></div>
          <div className="col-4 content col-12-sm pl-2-md pr-2-md">
            {street?.properties ? (
              <div>
                <div className="font-size-md mt-10 text-align-center font-size-sm-md pb-3">
                  Sélectionnez votre lieu de résidence
                </div>
                <div className="select-container">
                  <select
                    onChange={(e) =>
                      navigate('/inscription/form/', {
                        state: {
                          property: street.properties.find(
                            (property) => property.id === e.target.value,
                          ),
                          zone: zoneData,
                        },
                      })
                    }
                  >
                    <option value="">--</option>
                    {street.properties
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((p) => (
                        <option value={p.id}>{p.name}</option>
                      ))}
                  </select>
                </div>
                <div className="mt-5 font-size-md text-align-center font-size-sm-md">
                  Ou
                </div>
                <div className="mt-5 font-size-md text-align-center font-size-sm-md pb-3">
                  Saisissez une autre adresse postale
                </div>
              </div>
            ) : (
              <div className="font-size-md text-align-center font-size-sm-md pb-3">
                Saisissez votre adresse postale pour démarrer votre inscription
              </div>
            )}

            <AddressSearch
              id="addressSearch"
              zoneId={zoneData.id}
              prefillSearch={
                street !== null ? street.streetName + ', ' + street.city : ''
              }
              placeholder={
                street !== null
                  ? street.streetName + ', ' + street.city
                  : 'Mon adresse postale'
              }
            />
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10 mt-5-md">
          <div className="col-4 hidden-sm"></div>
          <div className="col-4 col-12-sm">
            <div className="zone-map text-align-right text-align-center-md pl-5-md pr-5-md mt-10-md">
              <ZoneMap
                zoneCoordinates={zoneData.location?.coordinates}
                propertyLocation={null}
              />
            </div>
          </div>
          <div className="col-4 hidden-sm"></div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md bold pb-1">
              Quartier {zoneData.name} à {zoneData.region}
            </div>
            <hr />

            {sliceIntoNArrays(zoneStreets, 4).map((streets, index) => (
              <div className="col-3 pt-1 font-size-sm">
                <ul className="full-width">
                  {streets.map((value, index) => (
                    <li className="pb-2">
                      <Link
                        to={`${value.slug}/`}
                        activeClassName="bold"
                        partiallyActive={true}
                      >
                        <span className="pr-1">
                          {value.streetName +
                            ' à ' +
                            value.city +
                            ' (' +
                            value.postalCode +
                            ')' +
                            (index + 1 < zoneStreets.length ? ',' : '')}
                        </span>
                      </Link>

                      {street !== null &&
                      value.streetName === street.streetName &&
                      street.properties != null ? (
                        <ul className="mt-1 pl-2 pb-3">
                          {street.properties
                            .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((p) => (
                              <li>
                                <Link
                                  to={
                                    '/ma-residence' + slashPrefix(p.slug) + '/'
                                  }
                                >
                                  {p.name}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="row mt-10">
          <div className="pt-2 text-color-purple-grey pl-2-md pr-2-md">
            <div className="font-size-md2 bold pb-1">
              Petites annonces dans le quartier {zoneData.name} à{' '}
              {zoneData.region}
            </div>
            <hr />

            {sliceIntoNArrays(servicesCategories, 2).map(
              (categories, index) => (
                <div className="col-3 pt-1">
                  <ul className="full-width">
                    {categories.map((value, index) => (
                      <li className="pb-2 pr-2">
                        <div className="font-size-md">
                          <Link
                            to={
                              '/petites-annonces/services' +
                              slashPrefix(value.slug) +
                              (street === null
                                ? slashPrefix(zoneData.slug)
                                : slashPrefix(street.slug)) +
                              '/'
                            }
                            activeClassName="bold"
                          >
                            Services > {value.name}
                          </Link>
                        </div>
                        <div className="font-size-xs pt-1">
                          <Link
                            to={
                              '/petites-annonces/services' +
                              slashPrefix(value.slug) +
                              (street === null
                                ? slashPrefix(zoneData.slug)
                                : slashPrefix(street.slug)) +
                              '/'
                            }
                            activeClassName="bold"
                          >
                            {value.seoTitle} dans le quartier {zoneData.name}
                            {street !== null ? `, ${street.streetName}` : ''}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ),
            )}

            {sliceIntoNArrays(itemsCategories, 2).map((categories, index) => (
              <div className="col-3 pt-1">
                <ul className="full-width">
                  {categories.map((value, index) => (
                    <li className="pb-2 pr-2">
                      <div className="font-size-md">
                        <Link
                          to={
                            '/petites-annonces/biens' +
                            slashPrefix(value.slug) +
                            (street === null
                              ? slashPrefix(zoneData.slug)
                              : slashPrefix(street.slug)) +
                            '/'
                          }
                          activeClassName="bold"
                        >
                          Biens > {value.name}
                        </Link>
                      </div>
                      <div className="font-size-xs pt-1">
                        <Link
                          to={
                            '/petites-annonces/biens' +
                            slashPrefix(value.slug) +
                            (street === null
                              ? slashPrefix(zoneData.slug)
                              : slashPrefix(street.slug)) +
                            '/'
                          }
                          activeClassName="bold"
                        >
                          {value.seoTitle} dans le quartier {zoneData.name}
                          {street !== null ? `, ${street.streetName}` : ''}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
